<template>
  <b-card class="home">
    <b-card-title class="title">
      <h2>
        Bienvenue sur <span class="applicasol">Applicasol</span>
      </h2>
    </b-card-title>
    <div class="page">
      <h2 align="center"></h2>

      <b-row>
        <b-col cols="12" md="1"><img src="@/assets/vignette_sol_reduit.png" style="width:70px"></b-col>
        <b-col cols="6" md="11"><p><strong>APPLICASOL</strong> est un système d’information référençant et facilitant la mise en commun de l’ensemble des applications thématiques réalisées à partir des bases de données cartographiques sur les sols, ainsi que les méthodes de traitement utilisées.</p></b-col>
      </b-row>
      <p class="mt-3">
        Des bases de données cartographiques sur les sols sont disponibles ou en cours de constitution sur le territoire français.
        Un certain nombre d’entre elles sont disponibles au format national DoneSol.
        De nombreuses applications, en réponse à des enjeux et des demandes exprimés localement, sont développées à partir de ces inventaires et pourraient bénéficier à d’autres acteurs.
        Les données géographiques sur les sols, combinées à d’autres informations (climat, relief, occupation du sol, pratiques agricoles, hydrologie, hydrogéologie, etc.)
        offrent une gamme d’applications thématiques très étendue<sup>1</sup> : gestion et protection des sols, gestion du territoire, aménagement, zonages, préservation de la biodiversité, etc.
        La complexité des méthodes de traitement mises en œuvre est variable, allant de la simple extraction et traitement de données sols,
        en passant par l’élaboration de règles de pédotransfert, jusqu’à la mise en place de modèles plus ou moins complexes intégrant des variables « non-sol ».
      </p>
      <p>
        Dans ce contexte, le <strong>Réseau Mixte Technologique (RMT) « Sols et Territoires »</strong> 
        s’est donné les moyens de mettre en place un système d’information appelé
        <strong>APPLICASOL</strong> avec plusieurs objectifs :
        <ul style="list-style-position: inside; list-style-type: square;">
          <li>Effectuer l’inventaire des applications réalisées à partir des bases de données cartographiques sur les sols dans les régions qui en disposent </li>
          <li>Intégrer dans une base de données des thématiques élaborées en facilitant la mise en commun des méthodes mises en œuvre et des résultats obtenus dans ces applications</li>
          <li>Faciliter l’accès à ces informations.</li>
        </ul>
      </p>
      <p class="mb-4">
        Les objectifs finaux de ce travail sont la diffusion de cette base de données via le site internet du RMT et l'organisation de son enrichissement.
      </p>

      <sup>1</sup> Le Bas, C. ; Schnebelen, N. Utilisation des données sols d'I.G.C.S. en France. Etat des lieux en 2006. Etude et Gestion des
      Sols. 2006, 13 (3) : 237-246
      <br><br>
      <div id="remerciements"><strong> Remerciements:</strong>
        <ul style="list-style-position: inside;">
          <li><span class="li-element">Concepteurs</span>: Marion Bardy, Aurélien Chapuis, Jean-Philippe Chenu, Sacha Desbourdes, Fatima Ezzayani, Jean-Luc Fort,
            Camille Guellier, Florent Millet, Joël Moulin, Olivier Scheurer, Nathalie Schnebelen, Benoit Toutain, Christine Lebas </li>
          <li><span class="li-element">Testeurs</span> Florence Heliès, Bertrand Laroche, Joëlle Sauter </li>
          <li><span class="li-element">Developpeurs</span>: Yohann Templier, Hakima Boukir, Antoine Schellenberger</li>
        </ul>
      </div>
    </div>
  </b-card>
</template>

<script>

export default {
  name: 'HomePage',
}
</script>

<style>
.home{
  text-align: justify;
  margin-left: 4em;
  margin-right: 4em;
  font-size: 18px;;
}

.title{
  color: #95775d;
  text-align:center;
}

.applicasol{
  font-weight:bold;
  font-variant: small-caps;
}

.page{
  margin-left: 8em;
  margin-right: 8em;
  padding-bottom: 4em;
  border: black;
}

.customPagination > li > button {
  color:black !important;
}

.li-element{
  color: #95775d ;
  font-weight: bold;
}


</style>
